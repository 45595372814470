@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100&display=swap');

:root {
  --bg-color: rgb(240, 240, 240);
  --font-color: rgb(0, 36, 36);
}

.pitch {
  text-decoration: none;
  color:var(--font-color) ;
  border: solid var(--font-color) 3px;
  border-radius: 5px;
  padding: 5px;
  font-size: larger;
  position: absolute;
  left: 5px;
  bottom: 5px;
  transition: 0.5s;

}

.pitch:hover {
  background-color: navy;
  color: white;
}

.root {
  color: var(--font-color);
  font-family: 'Libre Franklin', sans-serif;
  background-color: var(--bg-color);
}

.App {
  text-align: center;
  background-color: var(--bg-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-evenly;
}


.app-content {
  z-index: 1;
}

.orb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px
}

